<template>
    <div class="bg-white" v-loading='loadingVisible'>
        <p>
            versiunea 2.0
        </p>
        <el-form :label-width="labelWidth"  ref='my-form' @submit.prevent="save" >
            <el-row v-show="IdFisa != ''">
                <el-col :xs="24" :sm="24" :md="24" :lg="24">
                   <div class="padding-10" v-html="contentHtml"></div>  
                </el-col>   
            </el-row>  
            <el-row v-show="IdFisa == ''">
                <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <h3 class="mb-40 mt-40">Nu exista fisa trimisa pentru semnatura!</h3>
                </el-col>
            </el-row>
            <el-row v-if="IdFisa != ''">                                                           
                <el-col :md='24'>
                    <VueSignaturePad width="100%" height="200px" class="border-signature" ref="signaturePad"/>
                </el-col>    
                <el-col :md="24" style="text-align:center;" > 
                    <el-button type="warning" plain @click="undo" >Corecteaza</el-button><br/><br/>
                    <el-button class="mb-40" type="primary" style="width:70%" plain @click="save()" > Salveaza </el-button>
                </el-col>
                
            </el-row>
        </el-form>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import Constants from '@/backend/Constants';

    export default {
        name: "Fisa_confirmare_semnatura",
        extends: BasePage,
        data () {
            return {
                loadingVisible: false,
                Constants: Constants,
                labelPosition:'left',
                labelWidth: '',
                IdFisa:'', 
                parentHeight: 1000,
                contentHtml:'',
            }
        },

        created() {
            window.addEventListener("resize", this.onWindowResize);
        },
        destroyed() {
            window.removeEventListener("resize", this.onWindowResize);
        },

        methods: {
            onWindowResize(){
                var w = window.innerWidth;
                if(w > 500) {
                    this.labelWidth = '100px'
                } else {
                    this.labelWidth = ''
                }   
            },

            async refreshContent() {
                //intrebe serverul daca avem o fisa de semnat
                var response = await this.post(this.Constants.ServerUrl +"fisa_intrare/avem_ceva_de_semnat");
                if( response.AvemFisa ) {
                    this.IdFisa = response.Fisa.Id;
                    await this.getContent();
                }
            },

            undo() {
                this.$refs.signaturePad.undoSignature()
            },
            async getContent() {
                var response = await this.post(this.Constants.ServerUrl + 'dashboard_mecanic/fisa_pdf/' + this.IdFisa + '/1',{})
                this.contentHtml = response.Html
            },
            save: async function() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

                if(isEmpty) {
                    this.$alert('Semnatura client este obligatorie!', 'Atentionare', { confirmButtonText: 'OK', callback: action => {  } });
                } else {
                    await this.post( this.Constants.ServerUrl + "fisa_intrare/semneaza", { id: this.IdFisa, cargo: data});
                    
                    this.$message({ message: 'Semnatura a fost trimisa cu succes!', type: 'success' });
                    
                    this.IdFisa         = '';
                    this.contentHtml    = '';
                    this.$refs.signaturePad.undoSignature();
                }
            },

            reloadPage(){
                var me = this
                setTimeout(function () {
                    me.$router.go();
                    console.log('reloaded');                    
                }, 3000);
                
            }
        },
        mounted: function() {    
            this.onWindowResize();
            this.refreshContent();
        }
    };
</script>

<style lang="less" scoped>
    .input-group
    {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        width:100%;

        .input-group-input
        {
            position:relative;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            width: 1%;
        }

        .input-group-append
        {
            margin-left: -1px;
            display: flex;
        }
    }

    .full-width {
        width: 100%;
    }

    .border-signature {
      border:2px solid #CECECE;
    }

    .mb-desktop-48 {   
        margin-bottom:0px;
        margin-top:48px;

        @media(min-width:1200px) {
            margin-top: 0px;  
        }
    }

    .bg-white {
        background-color:#FFFFFF;
    }

    .mb-40 {
        margin-bottom:40px;
    }

    .mt-40 {
        margin-top:40px;
    }

    .padding-10{
        padding: 10px;
    }
</style>